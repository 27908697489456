import React from 'react'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <Typography variant="h3" style={{ color: 'black', padding: '16px', fontFamily: 'Ubuntu', textAlign:'center'}}>
            Stories
          </Typography>
        </div>
        <Box component="section">
          <Container maxWidth="lg">
            <div className="content">
              <BlogRoll />
            </div>
          </Container>
        </Box>
      </Layout>
    )
  }
}
