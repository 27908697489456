import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <Container maxWidth='xl'>
        {posts &&
          posts.map(({ node: post }) => (
            <div key={post.id} style={{paddingBottom: '32px'}}>
              <article
                className={`blog-list-item tile is-child box notification ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                <Card>
                <Grid container spacing={4} justify="center" >
                  {post.frontmatter.featuredimage ? (
                    <Grid item  xs={12} s={12} md={6} lg={6}>
                      <CardContent>
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${
                            post.frontmatter.title
                          }`,
                        }}
                      />
                      </CardContent>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} s={12} md={6} lg={6}> 
                  <CardContent>
                    <Typography variant='h5' component='h5'>
                      <Link to={post.fields.slug} >
                        {post.frontmatter.title}
                      </Link>
                    </Typography>

                    <Typography component='p' variant='caption'>
                      {post.frontmatter.date}
                    </Typography>

                  <Typography component='p' variant='body1'>
                    {post.excerpt}
                    <br />
                    <br />
                    <Link to={post.fields.slug}>
                      Keep Reading →
                    </Link>
                  </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>

              </article>
            </div>
          ))}
      </Container>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 300, quality: 100, cropFocus:CENTER) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
